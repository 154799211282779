import React, {useEffect, useRef} from "react";
import api from "../../../api/api.js";
import $ from 'jquery';
import 'datatables.net'
import languageES from 'datatables.net-plugins/i18n/es-MX';

const FoliosHome = () => {
    const tableRef = useRef(null);
    const dataTable = useRef(null);

    useEffect(() => {
        dataTable.current = $(tableRef.current).DataTable({
            processing: true,
            serverSide: true,
            language: languageES,
            async ajax(dataTablesParams, callback) {
                try {
                    const response = await api.get('/request_tickets', {
                        params: {
                            page: dataTablesParams.start / dataTablesParams.length + 1,
                            itemsPerPage: dataTablesParams.length,
                            search: dataTablesParams.search.value,
                        },
                    });

                    callback({
                        data: response.data['hydra:member'].map((item, index) => ({
                            ...item,
                            uniqueKey: `${item.id || 'temp'}-${index}` // Generar una clave única si `id` está vacío
                        })),
                        recordsTotal: response.data['hydra:totalItems'],
                        recordsFiltered: response.data['hydra:totalItems'],
                    });
                } catch (error) {
                    console.error('Error al cargar datos:', error);
                    callback({data: []});
                }
            },
            columns: [
                { title: 'ID', data: 'id' },
                { title: 'Cantidad de Folios', data: 'quantityTickets' },
                { title: 'Solicitado', data: 'createdAtAgo' },
                { title: 'sss', render: (data, type, row) => {
                     console.log(row)
                        return `Persdddasdon`;
                    }
                },
            ],
            rowId: 'id',
        });

        return () => {
            if (dataTable.current) {
                dataTable.current.destroy();
            }
        };
    }, []);


    return (
        <>
            <>
                <div className="col">
                    <div className="card">
                        <div className="card-body p-1">
                            <div className="card-title p-1">
                                <h5 className="card-title p-1">Historial de folios</h5>
                            </div>
                            <div className={"card-body"}>
                                <table ref={tableRef} className="display" style={{width: '100%'}}></table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default FoliosHome