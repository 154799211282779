import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import api from '@/api/api';


const CompanyFormModal = ({ show, handleClose, companyId, onSave }) => {
    const [companyType, setCompanyType] = useState('Fisica');
    const [companyData, setCompanyData] = useState({
        name: '',
        taxIdentification: '',
        phone: '',
        email: '',
        postalCode: '',
        businessName: ''
    });


    useEffect(() => {
        if (companyId) {
            api.get(`companies/${companyId}`)
                .then(response => {
                    const data = response.data;
                    setCompanyData({
                        ...companyData,
                        name: data.name,
                        taxIdentification: data.taxIdentification,
                        address: data.address,
                        phone: data.phone,
                        email: data.email,
                        postalCode: data.postalCode,
                        city: data.city,
                        businessName: data.businessName || ''
                    });
                    setCompanyType(data.type || 'Persona Física');
                })
                .catch(error => console.error('Error al cargar datos de la empresa:', error));
        }
    }, [companyId]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setCompanyData({
            ...companyData,
            [name]: value
        });
    };

    const handleTypeChange = (e) => {
        setCompanyType(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (companyId) {
                await api.put(`/companies/${companyId}`, {
                    ...companyData,
                    type: companyType
                });
            } else {
                await api.post('/companies', {
                    ...companyData,
                    type: companyType
                });
            }
            onSave();
            handleClose();
        } catch (error) {
            console.error('Error al guardar la información de la empresa:', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{companyId ? 'Editar Empresa' : 'Crear Empresa'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Tipo de Entidad</Form.Label>
                        <Form.Select value={companyType} onChange={handleTypeChange}>
                            <option value="fisica">Persona Física</option>
                            <option value="moral">Persona Moral</option>
                        </Form.Select>
                    </Form.Group>

                    {companyType === 'moral' && (
                        <Form.Group className="mb-3">
                            <Form.Label>Razón Social</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Razón Social de la empresa"
                                name="businessName"
                                value={companyData.businessName}
                                onChange={handleChange}
                                required={companyType === 'Persona Moral'}
                            />
                        </Form.Group>
                    )}

                    <Form.Group className="mb-3">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nombre de la Empresa o Persona Física"
                            name="name"
                            value={companyData.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>RFC</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="RFC de la Empresa o Persona Física"
                            name="taxIdentification"
                            value={companyData.taxIdentification}
                            onChange={handleChange}
                            required
                        />
                        {/*{error && <p style={{ color: 'red' }}>{error}</p>}*/}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Teléfono:</Form.Label>
                        <Form.Control
                            type="tel"
                            placeholder="Teléfono de contacto"
                            name="phone"
                            value={companyData.phone}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Correo Electrónico:</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Correo electrónico de contacto"
                            name="email"
                            value={companyData.email}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        {companyId ? 'Actualizar' : 'Crear'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default CompanyFormModal;