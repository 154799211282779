import React, { useState, useEffect } from 'react';
import {Modal, Button, Form, Col, Row, Alert} from 'react-bootstrap';
import api from '@/api/api';


const UserFormModal = ({ show, handleClose, userId, onSave }) => {
    const [formData, setFormData] = useState({
        username: "",
        roles: [],
        password: "",
    });

    const [errors, setErrors] = useState({
        username: "",
        password: "",
        roles: "",
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            setFormData((prev) => ({
                ...prev,
                roles: checked
                    ? [...prev.roles, value]
                    : prev.roles.filter((role) => role !== value),
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };
    //
    // const validateForm = () => {
    //     let valid = true;
    //     let newErrors = { username: "", password: "", roles: "" };
    //
    //     if (!formData.username.trim()) {
    //         newErrors.username = "El nombre de usuario es obligatorio.";
    //         valid = false;
    //     }
    //
    //     if (formData.password.length < 8 || !/[A-Z]/.test(formData.password)) {
    //         newErrors.password =
    //             "La contraseña debe tener al menos 8 caracteres y una letra mayúscula.";
    //         valid = false;
    //     }
    //
    //     if (formData.roles.length === 0) {
    //         newErrors.roles = "Debe seleccionar al menos un rol.";
    //         valid = false;
    //     }
    //
    //     setErrors(newErrors);
    //     return valid;
    // };
    //
    // const onSubmit = (e) => {
    //     e.preventDefault();
    //     if (validateForm()) {
    //         handleSubmit(formData); // Enviar los datos al padre
    //         handleClose(); // Cerrar el modal
    //     }
    // };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form >
                    <Form.Group className="mb-3">
                        <Form.Label>Nombre de usuario</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingresa el nombre de usuario"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            isInvalid={!!errors.username}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.username}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Ingresa una contraseña"
                            name="password"
                            value={formData.password}
                            // onChange={handleChange}
                            isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Roles</Form.Label>
                        <div>
                            <Form.Check
                                type="checkbox"
                                label="ROLE_USER"
                                value="ROLE_USER"
                                checked={formData.roles.includes("ROLE_USER")}
                                // onChange={handleChange}
                            />
                            <Form.Check
                                type="checkbox"
                                label="ROLE_ADMIN"
                                value="ROLE_ADMIN"
                                checked={formData.roles.includes("ROLE_ADMIN")}
                                // onChange={handleChange}
                            />
                        </div>
                        {errors.roles && <Alert variant="danger">{errors.roles}</Alert>}
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Guardar Usuario
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );

}

export default UserFormModal