const menuItems = [
    {
      name: 'Usuarios - Compañias',
      icon: 'bi-person',
      path: '',
      allowedRoles: ['ROLE_ADMIN'],
      subMenu: [
        { name: 'Compañias', icon: '', path: '/companies', allowedRoles: ['ROLE_ADMIN'] },
        { name: 'Usarios Internos', icon: 'bi-person', path: '/users-internal', allowedRoles: ['ROLE_ADMIN'] },
      ]
    },
    {
      name: 'Folios',
      icon: 'bi-ticket-fill',
      path: '',
      allowedRoles: ['ROLE_ADMIN'],
      subMenu: [
        { name: 'Folios comprados', icon: 'bi-ticket-detailed', path: '/folios', allowedRoles: ['ROLE_ADMIN'] },
        { name: 'Folios facturas', icon: 'bi-ticket-detailed', path: '/folios', allowedRoles: ['ROLE_ADMIN'] },
        // { name: 'System Logs',icon: '', path: '/settings/logs', allowedRoles: ['ROLE_ADMIN'] },
      ],
    },
    // {
    //   name: 'Profile',
    //   icon: '',
    //   path: '/profile',
    //   allowedRoles: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_GUEST'],
    // },
  ];
  
  export default menuItems;