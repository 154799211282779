import { Route } from 'react-router-dom';
import { ProtectedRoute } from './routes/ProtectedRoute.jsx';

import TemplateAdmin from "./pages/admin/TemplateAdmin.jsx";
import FoliosHome from "./pages/admin/pages/FoliosHome.jsx";


const AdminRoutes = (
    <Route element={<ProtectedRoute allowedRoles={['ROLE_ADMIN']} />}>
        <Route path="/folios" element={<TemplateAdmin path="/folios" title={"Administrador de Folios"}  content={<FoliosHome />} />} />
    </Route>
);


export default AdminRoutes